<template>
	<a-space direction="vertical">
		<a-card title="查询条件">
			<a-form-model @submit.prevent="onSearch" layout="horizontal" v-bind="{ labelCol: { span: 6 }, wrapperCol: { span: 16 } }">
				<a-row>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="ID"><a-input v-model="query.uid" placeholder="请输入用户ID"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="手机号码/昵称"><a-input v-model="query.mobile" placeholder="请输入手机号码/昵称"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="类目">
							<a-cascader
								:fieldNames="{ value: 'id', label: 'name', children: 'children' }"
								:options="categories"
								placeholder="请选择类目"
								:value="selectedCascader"
								@change="onCascaderChange"
							/>
						</a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="订单时间"><a-range-picker :value="selectedDateRange" @change="onChange" style="width:100%" /></a-form-model-item>
					</a-col>

					<a-col :md="8" :sm="24">
						<a-form-model-item label="产品名称">
							<a-select v-model="query.productId" placeholder="请选择产品名称" style="width:100%" :allow-clear="true">
								<a-select-option v-for="item in products" :key="item.id" :value="String(item.id)">
									<a-space>
										<a-avatar shape="square" :size="24" :src="item.banner"></a-avatar>
										{{ item.productName }}
									</a-space>
								</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>

					<a-col :md="8" :sm="24">
						<a-form-model-item label="订单ID"><a-input v-model="query.orderId" placeholder="请输入订单ID"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item :wrapper-col="{ span: 16, offset: 6 }">
							<a-space>
								<a-button type="primary" html-type="submit">查询</a-button>
								<a-button @click="onReset">重置</a-button>
							</a-space>
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
		</a-card>

		<a-card title="财务管理">
			<a-space slot="extra">
				<a-button type="primary" @click="onDaily(2)">每日云库存</a-button>
				<a-button type="primary" @click="onDaily(1)">每日余额</a-button>
				<a-button @click="onExport" style="margin-left: 32px;">导出订单</a-button>
			</a-space>

			<div class="statistics">
				<span>订单总数：{{ lists.total | numberFormat }}</span>
				<span>公司收入：{{ lists.income }}</span>
				<span>公司支出：{{ lists.expend }}</span>
				<span>产品数量：{{ lists.productExpend }}</span>
				<span>被兑换数量：{{ lists.productReturn }}</span>
			</div>

			<a-table
				:data-source="lists.data"
				:pagination="{ current: lists.current_page, pageSize: lists.per_page, total: lists.total }"
				@change="onPageChange"
				:scroll="{ x: 'max-content' }"
			>
				<a-table-column key="userId" title="用户信息" data-index="userId">
					<template slot-scope="text, record">
						<div>
							{{ text }}
							<a href="javascript:;" v-clipboard:copy="text" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
							<br />
							{{ record.nickname }} （{{ record.mobile }}
							<a href="javascript:;" v-clipboard:copy="record.mobile" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
							）
						</div>
					</template>
				</a-table-column>
				<a-table-column key="ctime" title="订单时间" data-index="ctime" />
				<a-table-column key="amount" title="数量/金额" data-index="amount" align="center">
					<template slot-scope="text, record">
						<span>
							{{ record.amount }}
							<a-tooltip v-if="record.remarks">
								<template slot="title">
									{{ record.remarks }}
								</template>
								<a-icon type="question-circle" theme="filled" style="color:#999999;" />
							</a-tooltip>
						</span>
					</template>
				</a-table-column>
				<a-table-column key="itemOneStr" title="类目一" data-index="itemOneStr" align="center">
					<template slot-scope="banner, record">
						<a-tag :color="['', '#13c2c2', '#87d068', '#108ee9', '#fa8c16'][record.itemOne]">{{ record.itemOneStr }}</a-tag>
					</template>
				</a-table-column>
				<a-table-column key="itemTwoStr" title="类目二" data-index="itemTwoStr" align="center"></a-table-column>
				<a-table-column key="banner" title="产品" data-index="banner" align="center">
					<template slot-scope="banner, record">
						<a-avatar shape="square" :size="64" style="width:128px;height:64px" :src="banner" @click="onPreview(banner)"/>
					</template>
				</a-table-column>
				<a-table-column key="typeStr" title="订单类型" data-index="typeStr" align="center">
					<template slot-scope="banner, record">
						<a-tag color="green" v-if="record.type == 1">{{ record.typeStr }}</a-tag>
						<a-tag color="cyan" v-else-if="record.type == 2">{{ record.typeStr }}</a-tag>
						<a-tag color="blue" v-else-if="record.type == 3">{{ record.typeStr }}</a-tag>
						<a-tag color="orange" v-else>{{ record.typeStr }}</a-tag>
					</template>
				</a-table-column>
				<a-table-column key="action" title="操作" fixed="right" align="center">
					<template slot-scope="text, record">
						<a-space>
							<a-button type="primary" size="small" v-if="record.recommendId" @click="onView(record)">订单详情</a-button>
							<a-button size="small" v-if="record.recommendId && [1, 2].includes(record.type)" @click="onCommission(record)">分佣详情</a-button>
							<!-- <a-button size="small" v-if="record.recommendId" @click="onCommission(record)">分佣详情</a-button> -->
						</a-space>
					</template>
				</a-table-column>
			</a-table>
		</a-card>

		<a-modal v-model="modalCommission.visible" title="分佣详情" :width="800" :footer="null">
			<a-table :data-source="modalCommission.data" :pagination="false" :scroll="{ x: 'max-content' }">
				<a-table-column key="mobile" title="用户" data-index="mobile">
					<template slot-scope="text, record">
						{{ record.nickname }}
						<br />
						({{ record.mobile }})
					</template>
				</a-table-column>
				<a-table-column key="money" title="金额" data-index="money" />
				<a-table-column key="serviceCharge" title="手续费" data-index="serviceCharge" />
				<a-table-column key="incomeTypeStr" title="分佣类型" data-index="incomeTypeStr" />
				<a-table-column key="commissionTypeStr" title="分佣途径" data-index="commissionTypeStr" />
				<a-table-column key="statusStr" title="状态" data-index="statusStr" />
				<a-table-column key="settleTime" title="结算时间" data-index="settleTime" />
			</a-table>
		</a-modal>

		<a-modal title="业绩订单列表" v-model="modalOrder.visible" :centered="true" :footer="null" :bodyStyle="{ padding: '10px' }">
			<div class="nodata" style="padding: 30px 0;" v-if="modalOrder.lists.total == 0"><a-empty></a-empty></div>
			<div class="order-lists" v-else>
				<div style="max-height: 78vh; overflow-y: auto; ">
					<div class="order-item" v-for="(item, index) in modalOrder.lists.data">
						<div class="header">
							<div class="sn">
								<b>{{ modalOrder.lists.per_page * (modalOrder.lists.current_page - 1) + index + 1 }}</b>
								、订单：{{ item.id }}
								<a-tag :color="['', '#2db7f5', '#87d068', '#e98f36', '#108ee9', '#a4579d'][item.type]">{{ item.typeStr }}</a-tag>
							</div>
							<div>{{ item.statusStr }}</div>
						</div>
						<div class="content">
							<img class="cover" :src="item.payUrl" />
							<div class="title">
								<div class="item">用户姓名：{{ item.nickname }} ({{ item.mobile }})</div>
								<div class="item" v-if="!!item.recommendMobile">部门姓名：{{ item.recommendNickName }} ({{ item.recommendMobile }})</div>
								<div class="stitle">
									<div>订单数量： {{ item.nums }}</div>
									<div v-show="item.giveNum > 0">赠送数量： {{ item.giveNum }}</div>
								</div>
								<div class="stitle">
									<div>发货人： {{ item.sendNickname }} ({{ item.sendMobile }})</div>
								</div>
							</div>
						</div>
						<div class="footer">
							<div class="date">{{ item.ctime }}</div>
							<div>
								<div class="total">
									<a-avatar :size="24" :src="require('@/assets/icon-wechat.png')" v-if="item.payType == 1" />
									<a-avatar :size="24" :src="require('@/assets/icon-alipay.png')" v-else-if="item.payType == 2" />
									<a-avatar :size="24" :src="require('@/assets/icon-balance.png')" v-else-if="item.payType == 3" />
									<a-avatar :size="24" :src="require('@/assets/icon-cart.png')" v-else-if="item.payType == 4" />
									<a-avatar :size="24" :src="require('@/assets/icon-offline.png')" v-else-if="item.payType == 5" />
									<a-tooltip placement="top" :getPopupContainer="triggerNode => triggerNode.parentNode" v-if="item.blendRemarks">
										<template slot="title">
											<span>{{ item.blendRemarks }}</span>
										</template>
										<a-icon type="question-circle" />
									</a-tooltip>
									¥{{ item.money }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</a-modal>

		<a-modal :title="modalDaily.title" v-model="modalDaily.visible" :width="800" :centered="true" :footer="null" :bodyStyle="{ padding: '10px' }">
			<div class="order-lists">
				<a-row type="flex">
					<a-col flex="1">
						<a-form-model layout="inline">
							<a-form-model-item label="订单时间"><a-range-picker :value="selectedDailyDateRange" @change="onDailyChange" style="width:100%" /></a-form-model-item>
						</a-form-model>
					</a-col>
					<a-col><a-button type="primary" @click="onExportDaily">导出</a-button></a-col>
				</a-row>
				<!-- <div class="nodata" style="padding: 30px 0;" v-if="modalDaily.lists.total == 0"><a-empty></a-empty></div> -->
				<a-table
					style="margin-top: 10px;"
					:data-source="modalDaily.lists.data"
					:pagination="{ current: modalDaily.lists.current_page, pageSize: modalDaily.lists.per_page, total: modalDaily.lists.total }"
					@change="onDailyPageChange"
					:scroll="{ x: 'max-content' }"
					bordered
				>
					<a-table-column key="day" title="时间" data-index="day" />
					<a-table-column v-if="modalDaily.query.type == 1" key="amount" title="可提现余额" data-index="amount" align="center"></a-table-column>
					<a-table-column v-if="modalDaily.query.type == 2" key="amount1" title="【汤为丽】御膳汤" data-index="amount1" align="center"></a-table-column>
					<a-table-column v-if="modalDaily.query.type == 2" key="amount6" title="【东方㿟】白番茄" data-index="amount6" align="center"></a-table-column>
					<a-table-column v-if="modalDaily.query.type == 2" key="amount2" title="【五不高】二酯油" data-index="amount2" align="center"></a-table-column>
					<a-table-column v-if="modalDaily.query.type == 2" key="amount3" title="【五不高】清畅汤" data-index="amount3" align="center"></a-table-column>
					<a-table-column v-if="modalDaily.query.type == 2" key="amount4" title="【五不高】80瓶装油" data-index="amount4" align="center"></a-table-column>
					<a-table-column v-if="modalDaily.query.type == 2" key="amount5" title="【五不高】50瓶装油" data-index="amount5" align="center"></a-table-column>
				</a-table>
			</div>
		</a-modal>
	</a-space>
</template>

<script>
import moment from 'moment';
import { parseQuery, numberFormat } from '@/utils';
const formatter = 'YYYY-MM-DD';

const DEFAULT_QUERY = {
	page: 1,
	size: 10,
	mobile: undefined,
	beginTime: moment()
		.startOf('month')
		.format(formatter),
	endTime: moment().format(formatter),
	uid: undefined,
	productId: undefined,
	orderId: undefined,
	itemOne: undefined,
	itemTwo: undefined
};

export default {
	data() {
		return {
			loading: false,
			products: [],
			categories: [],
			query: Object.assign({}, DEFAULT_QUERY),
			lists: {
				data: [],
				current_page: 1,
				per_page: 10,
				total: 0,
				income: 0,
				expend: 0,
				productExpend: 0
			},
			modalCommission: {
				visible: false,
				data: []
			},
			modalOrder: {
				visible: false,
				query: {
					orderId: undefined
				},
				lists: {
					data: [],
					current_page: 1,
					per_page: 10,
					total: 0
				}
			},
			modalDaily: {
				visible: false,
				query: {
					type: undefined,
					beginTime: moment()
						.startOf('month')
						.format(formatter),
					endTime: moment().format(formatter),
					page: 1
				},
				lists: {
					data: [],
					current_page: 1,
					per_page: 10,
					total: 0
				}
			}
		};
	},
	computed: {
		selectedDateRange() {
			const { beginTime, endTime } = this.query;
			if (beginTime && endTime) {
				return [moment(beginTime, formatter), moment(endTime, formatter)];
			} else {
				return null;
			}
		},
		selectedDailyDateRange() {
			const { beginTime, endTime } = this.modalDaily.query;
			if (beginTime && endTime) {
				return [moment(beginTime, formatter), moment(endTime, formatter)];
			} else {
				return null;
			}
		},
		selectedCascader() {
			const { itemOne, itemTwo } = this.query;
			if (itemOne && itemTwo) {
				return [itemOne, itemTwo];
			} else {
				return null;
			}
		}
	},
	async mounted() {
		const categories = await this.$api.get('/finance_query_level');
		this.categories = (categories && categories.data) || [];
		const response = await this.$api.get('/user_query_product/5');
		this.products = (response && response.data) || [];
		this.getFinances();
	},
	filters: {
		numberFormat
	},
	methods: {
		async getFinances() {
			const response = await this.$api.get('/finance_list', {
				params: this.query
			});
			if (response && response.code == 200) {
				response.data.total = parseInt(response.data.total);
				this.lists = Object.assign(this.lists, response.data);
				// location.hash = this.$route.path + '?' + parseQuery(this.query);
			}
		},
		async onPageChange(pagination) {
			this.query.page = pagination.current;
			this.query.size = pagination.pageSize;
			this.getFinances();
		},
		async onSearch() {
			this.query.page = 1;
			this.getFinances();
		},
		async onReset() {
			this.query = Object.assign({}, DEFAULT_QUERY);
			this.getFinances();
		},
		async onChange(o, dateStr) {
			if (dateStr.length === 2) {
				this.query.beginTime = dateStr[0];
				this.query.endTime = dateStr[1];
			} else {
				this.query.beginTime = undefined;
				this.query.endTime = undefined;
			}
		},
		async onCascaderChange(values) {
			if (values.length === 2) {
				this.query.itemOne = values[0];
				this.query.itemTwo = values[1];
			} else {
				this.query.itemOne = undefined;
				this.query.itemTwo = undefined;
			}
		},
		async onCommission(record) {
			const response = await this.$api.get(`/commission_detail/${record.recommendId}`);
			console.info('response', response);
			if (response && response.code == 200) {
				this.modalCommission.data = response.data;
				this.modalCommission.visible = true;
			} else {
				this.$message.error(response.msg);
			}
		},
		async onExport() {
			this.$store.dispatch('setLoading', true);
			const response = await this.$api.get('/finance_list_export', {
				params: this.query,
				responseType: 'arraybuffer'
			});
		},
		async onView(record) {
			this.modalOrder.query = {
				orderId: record.recommendId,
				page: 1
			};
			this.modalOrder.lists = {
				data: [],
				current_page: 1,
				per_page: 10,
				total: 0
			};
			this.getOrders();
			this.modalOrder.visible = true;
		},
		async getOrders() {
			const response = await this.$api.get('/agent_list', {
				params: this.modalOrder.query
			});
			if (response && response.code == 200) {
				this.modalOrder.lists = Object.assign(this.modalOrder.lists, response.data);
			}
		},
		async onDaily(type) {
			this.modalDaily.query.type = type;
			this.modalDaily.title = type == 1 ? '每日可提现余额' : '每日云库存';
			const response = await this.$api.get('/finance_daily', {
				params: this.modalDaily.query
			});
			if (response && response.code == 200) {
				this.modalDaily.lists = Object.assign(this.modalDaily.lists, response.data);
				this.modalDaily.visible = true;
			}
		},
		async onDailyChange(o, dateStr) {
			if (dateStr.length === 2) {
				this.modalDaily.query.beginTime = dateStr[0];
				this.modalDaily.query.endTime = dateStr[1];
			} else {
				this.modalDaily.query.beginTime = undefined;
				this.modalDaily.query.endTime = undefined;
			}
			this.modalDaily.query.page = 1;
			this.onDaily(this.modalDaily.query.type);
		},
		async onDailyPageChange(pagination) {
			this.modalDaily.query.page = pagination.current;
			this.modalDaily.query.size = pagination.pageSize;
			this.onDaily(this.modalDaily.query.type);
		},
		async onExportDaily(){
			this.$store.dispatch('setLoading', true);
			const response = await this.$api.get('/finance_daily_export', {
				params: this.modalDaily.query,
				responseType: 'arraybuffer'
			});
		}
	}
};
</script>

<style lang="less">
.order-item {
	padding: 14px 20px 20px 14px;
	border-bottom: #eaeaea 1px solid;
	.header {
		display: flex;
		align-items: center;
	}

	.content {
		margin-top: 10px;
		display: flex;
	}

	.footer {
		margin-top: 10px;
		display: flex;
		align-items: center;
	}

	.cover {
		display: block;
		width: 100px;
		height: 100px;
		border-radius: 4px;
	}

	.sn {
		flex: 1;
		color: #838383;
		font-size: 13px;
	}

	.status {
		font-size: 14px;
		font-weight: 500;
		color: #fc873a;
	}

	.title {
		margin-left: 10px;
		color: #333333;
		font-size: 14px;
		flex: 1;
		line-height: 22px;
		min-width: 0;

		.item {
			height: 24px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

			.label {
				float: left;
				width: 60px;
				text-align: justify;
				&::after {
					content: '';
					width: 100%;
					display: inline-block;
					height: 0;
				}
			}
		}

		.pname {
			line-height: 20px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}

	.stitle {
		color: #555555;
		margin-top: 10px;
		font-size: 13px;
		// display: flex;
		//  div{
		// 	 flex: 1;
		//  }

		& + .stitle {
			margin-top: 4px;
		}
	}

	.meta {
		// width: 158px;
		text-align: right;
		color: #666666;
		padding-top: 16px;
	}

	.price {
		font-size: 14px;
	}

	.quantity {
		margin-top: 16px;
		font-size: 13px;
	}

	.date {
		flex: 1;
		color: #999999;
		font-size: 13px;
	}

	.total {
		font-size: 14px;
		font-weight: 500;
		color: #333333;

		&::before {
			font-size: 12px;
			font-weight: 400;
			content: '订单金额：';
		}
	}
	.vtotal {
		// margin-left: 20px;
		font-size: 14px;
		font-weight: 500;
		color: #333333;

		&::before {
			font-size: 12px;
			font-weight: 400;
			content: '业绩金额：';
		}
	}
}
.pagination {
	padding: 10px 0;
	text-align: right;
}
</style>
